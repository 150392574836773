/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-walking': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.5 1.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0M6.44 3.752A.75.75 0 017 3.5h1.445c.742 0 1.32.643 1.243 1.38l-.43 4.083a1.8 1.8 0 01-.088.395l-.318.906.213.242a.8.8 0 01.114.175l2 4.25a.75.75 0 11-1.357.638l-1.956-4.154-1.68-1.921A.75.75 0 016 8.96l.138-2.613-.435.489-.464 2.786a.75.75 0 11-1.48-.246l.5-3a.75.75 0 01.18-.375l2-2.25z"/><path pid="1" d="M6.25 11.745v-1.418l1.204 1.375.261.524a.8.8 0 01-.12.231l-2.5 3.25a.75.75 0 11-1.19-.914zm4.22-4.215l-.494-.494.205-1.843.006-.067 1.124 1.124h1.44a.75.75 0 010 1.5H11a.75.75 0 01-.531-.22z"/>',
    },
});
